/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action, observable, computed } from 'mobx';
import _ from 'lodash';
import Cookies from 'js-cookie';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import ToastStore from './ToastStore';
import AuthorizedAccountRequest from '../models/AuthorizedAccountRequestModel';
import User from '../models/UserModel';
import Invitation from '../models/InvitationModel';
import ColumnDefinitions from '../static/ColumnDefinitions';
import Paginator from '../models/PaginatorModel';
import ProfileTemplate from '../models/ProfileTemplateModel';

axiosCancel(axios, {
  debug: false, // default
});

class AccountUsersStore {
  @observable activeTab = 'users';
  @observable activeRequest = '';
  @observable users = [];
  @observable searchUsers = [];
  @observable authorizedAccountRequests = [];
  @observable invitations = [];
  @observable newInvitation = new Invitation();
  @observable requestColumns = ColumnDefinitions.authorizedAccountRequestColumns;
  @observable userColumns = ColumnDefinitions.userColumns;
  @observable invitationColumns = ColumnDefinitions.invitationColumns;
  @observable pagination = new Paginator();
  @observable activeProfileTemplate = new ProfileTemplate(); // for the dataview tab
  @observable profileTemplates = []; // Array<ProfileTemplate>

  @computed get usersAbleToBeInvited() {
    return _.filter(this.users, user => {
      return user.lastSignInAt.length === 0;
    });
  }

  @action
  setActiveTab(tab) {
    this.activeTab = tab;
  }

  @action addProfileTemplate(profileTemplate: ProfileTemplate) {
    this.profileTemplates = _.unionBy([profileTemplate], this.profileTemplates, 'id');
  }

  @action
  setActiveProfileTemplate(profileTemplate: ProfileTemplate) {
    this.activeProfileTemplate = profileTemplate;
  }

  @action
  createPaginator(headers) {
    const newPagination = new Paginator(
      headers['x-next-page'],
      headers['x-offset'],
      headers['x-page'],
      headers['x-per-page'],
      headers['x-prev-page'],
      headers['x-total'],
      headers['x-total-pages']
    );
    this.pagination = newPagination;
  }

  @action
  addRequest(request) {
    const newRequest = new AuthorizedAccountRequest(
      request.id,
      request.account_id,
      request.account_subdomain,
      request.authorized_account_id,
      request.approved,
      request.approved_by,
      request.created_at,
      request.created_by,
      request.deleted_at,
      request.deleted_by,
      request.updated_at,
      request.updated_by,
      request.user_id
    );
    const newUser = new User(
      request.user.id,
      request.user.email,
      request.user.first_name,
      request.user.last_name,
      request.user.full_name,
      request.user.account_id,
      request.user.human_friendly_id,
      request.user.is_current_user,
      request.user.is_account_owner,
      request.user.image_file_name,
      request.user.background_image_file_name,
      request.user.last_sign_in_at,
      request.user.locale,
      request.user.theme,
      request.user.created_at,
      request.user.updated_at,
      request.user.deleted_at,
      request.user.created_by,
      request.user.updated_by,
      request.user.deleted_by
    );
    newRequest.user = newUser;

    this.authorizedAccountRequests = _.unionBy([newRequest], this.authorizedAccountRequests, 'id');
    this.sortRequests();
  }

  @action
  addUser(data) {
    const newUser = new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );

    this.users = _.unionBy([newUser], this.users, 'id');
    this.sortUsers();
  }

  @action
  addSearchUser(data) {
    const newSearchUser = new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );

    this.searchUsers = _.unionBy([newSearchUser], this.searchUsers, 'id');
    this.sortSearchUsers();
  }

  @action
  addInvitation(data) {
    const newInvitation = new Invitation(
      data.id,
      data.accepted,
      data.account_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.email,
      data.updated_at,
      data.updated_by,
      data.user_id
    );

    this.invitations = _.unionBy([newInvitation], this.invitations, 'id');
    this.sortInvitations();
  }

  @action
  updateNewInvitation(data, attribute) {
    this.newInvitation[attribute] = data;
  }

  @action
  removeUser(data) {
    this.users = _.filter(this.users, o => o.id !== data.id);
    this.sortUsers();
  }

  @action
  removeInvitation(data) {
    this.invitations = _.filter(this.invitations, o => o.id !== data.id);
    this.sortInvitations();
  }

  @action
  removeRequest(request) {
    const newRequests = _.filter(this.authorizedAccountRequests, o => o.id !== request.id);
    this.authorizedAccountRequests = newRequests;
  }

  @action
  fetchRequests(accountId, xPage, xPerPage, xOffset) {
    this.authorizedAccountRequests = [];
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get(
            'apiEnv'
          )}/authorized_account_requests?account_id=${accountId}&page=${xPage}&per_page=${xPerPage}&offset=${xOffset}`
        )
        .then(response => {
          this.createPaginator(response.headers);
          _.map(response.data, request => {
            this.addRequest(request);
          });
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  fetchInvitations(xPage, xPerPage, xOffset) {
    this.invitations = [];
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/invitations?page=${xPage}&per_page=${xPerPage}&offset=${xOffset}`)
        .then(response => {
          this.createPaginator(response.headers);
          _.map(response.data, invitation => {
            this.addInvitation(invitation);
          });
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  fetchUsers(xPage, xPerPage, xOffset, query, importId) {
    this.users = [];
    return new Promise((resolve, reject) => {
      let url = `${Cookies.get('apiEnv')}/users?page=${xPage}&per_page=${xPerPage}&offset=${xOffset}&query=${query}`;
      if (importId.length > 0) {
        url = `${url}&import_id=${importId}`;
      }
      axios
        .get(url, {
          requestId: 'userSearch',
        })
        .then(response => {
          this.createPaginator(response.headers);
          _.map(response.data, user => {
            this.addUser(user);
          });
          resolve(response);
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            reject(error);
          } else {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            reject(error);
          }
        });
    });
  }

  @action
  fetchAllUsers() {
    this.users = [];
    return new Promise((resolve, reject) => {
      const url = `${Cookies.get('apiEnv')}/users?page=1&per_page=1&offset=0`;
      axios
        .get(url)
        .then(response => {
          const total = response.headers['x-total'];
          const newUrl = `${Cookies.get('apiEnv')}/users?page=1&per_page=${total}&offset=0`;
          axios
            .get(newUrl)
            .then(res => {
              _.map(res.data, user => {
                this.addUser(user);
              });
              resolve(response);
            })
            .catch(error => {
              const errors = error.response.data.error.join(', ');
              ToastStore.showToast(errors, 'danger');
              reject(error);
            });
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  fetchUsersQuery(query, requestId) {
    this.activeRequest = requestId;
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/users?query=${query}`, {
          requestId: 'userSearch',
        })
        .then(response => {
          let total = response.data.length;
          _.map(response.data, user => {
            if (this.activeRequest === requestId) {
              this.addSearchUser(user);
            }
            total--;
          });
          if (total === 0) {
            resolve(response);
          }
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            reject(error);
          } else {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            reject(error);
          }
        });
    });
  }

  @action
  resetSearchUsers() {
    return new Promise(resolve => {
      this.searchUsers = [];
      resolve();
    });
  }

  @action
  changeUser(userId, newObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/users/${userId}`, newObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  deleteUser(userId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/users/${userId}`)
        .then(response => {
          this.pagination.xTotal--;
          this.removeUser(response.data);
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  approveRequest(request) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/authorized_account_requests/${request.id}`, { approved: true })
        .then(response => {
          resolve(response);
          this.addRequest(response.data);
          ToastStore.showToast(I18n.t('js.request_approved'), 'success');
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  rejectRequest(request) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/authorized_account_requests/${request.id}`)
        .then(response => {
          resolve(response);
          this.removeRequest(response.data);
          ToastStore.showToast(I18n.t('js.request_removed'), 'success');
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }
  //This function used in multiple component, changed email to lowercase 
  @action
  createInvitation() {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/invitations`, {
          email: this.newInvitation.email.toLowerCase(),
        })
        .then(response => {
          resolve(response);
          this.addInvitation(response.data);
          this.newInvitation = new Invitation();
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  deleteInvitation(invitationId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/invitations/${invitationId}`)
        .then(response => {
          this.removeInvitation(response.data);
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  setRequestSortParams(param) {
    const newRequestColumns = this.requestColumns;
    _.map(newRequestColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.requestColumns = newRequestColumns;
    this.sortRequests();
  }

  @action
  setUserSortParams(param) {
    const newUserColumns = this.userColumns;
    _.map(newUserColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.userColumns = newUserColumns;
    this.sortUsers();
  }

  @action
  setInvitationSortParams(param) {
    const newInvitationColumns = this.invitationColumns;
    _.map(newInvitationColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.invitationColumns = newInvitationColumns;
    this.sortInvitations();
  }

  @action
  sortRequests() {
    let newRequests = this.authorizedAccountRequests;
    const activeColumn = _.find(this.requestColumns, o => o.active);
    newRequests = _.orderBy(newRequests, [activeColumn.id], [activeColumn.sortDir]);
    if (activeColumn.id === 'fullName') {
      newRequests = _.orderBy(newRequests, o => o.user.fullName, [activeColumn.sortDir]);
    }
    this.authorizedAccountRequests = newRequests;
  }

  @action
  sortUsers() {
    let newUsers = this.users;
    const activeColumn = _.find(this.userColumns, o => o.active);
    newUsers = _.orderBy(newUsers, [activeColumn.id], [activeColumn.sortDir]);
    this.users = newUsers;
  }

  @action
  sortSearchUsers() {
    let newSearchUsers = this.searchUsers;
    newSearchUsers = _.orderBy(newSearchUsers, ['firstName'], ['asc']);
    this.searchUsers = newSearchUsers;
  }

  @action
  sortInvitations() {
    let newInvitations = this.invitations;
    const activeColumn = _.find(this.invitationColumns, o => o.active);
    newInvitations = _.orderBy(newInvitations, [activeColumn.id], [activeColumn.sortDir]);
    this.invitations = newInvitations;
  }

  @action resetStartingAttributes() {
    this.activeRequest = '';
    this.users = [];
    this.searchUsers = [];
    this.authorizedAccountRequests = [];
    this.invitations = [];
    this.newInvitation = new Invitation();
    this.requestColumns = ColumnDefinitions.authorizedAccountRequestColumns;
    this.userColumns = ColumnDefinitions.userColumns;
    this.invitationColumns = ColumnDefinitions.invitationColumns;
    this.pagination = new Paginator();
  }
}

const store = new AccountUsersStore();
export default store;
